import { AppContext } from "next/app";
import { endpoints } from "config";

type QueryParam = string | string[] | undefined;

export function transformQueryParamToString(param: QueryParam): string {
  if (!param) return "";
  if (Array.isArray(param)) return param.join("");
  return param;
}

export function getBasePathWithAppId(appId: QueryParam): string {
  const appIdAsString = transformQueryParamToString(appId);
  return `${endpoints.api}/app_id/${appIdAsString}`;
}

export function isPublicRoute(path: string): boolean {
  // TODO: Move to constants?
  const root = "/";
  const publicRoutes = [
    "/sign-in",
    "/forgot-password",
    "/faq",
    "/support",
    "/terms",
    "/privacy",
    "/get-started",
  ];
  return path === root || publicRoutes.includes(path);
}

export function hasAuthTokenInContext(appContext: AppContext): boolean {
  const cookie = transformQueryParamToString(
    appContext?.ctx?.req?.headers.cookie
  );
  return !!cookie.match(/wellspace-token/g);
}

export function isServerSide(appContext: AppContext): boolean {
  return !!(typeof window === "undefined" && appContext?.ctx?.res?.writeHead);
}

export function serverSideRedirect(
  appContext: AppContext,
  location: string
): void {
  appContext?.ctx?.res?.writeHead(302, { Location: location });
  appContext?.ctx?.res?.end();
}

export function getBaseGQLPathWithAppId(appId: QueryParam): string {
  const appIdAsString = transformQueryParamToString(appId);
  return `${endpoints.api}/app_id/${appIdAsString}/graphql`;
}

export function getBaseGQLPathWithAppIdFromWindow(): string {
  let appId = "";
  if (process.browser) {
    const pathname = window?.location?.pathname || "";
    appId = pathname.split("/")[2] || "";
  }
  return `${endpoints.api}/app_id/${appId}/graphql`;
}
